/* eslint-disable */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import "./index.css";
import {
  TextField,
  MenuItem,
  FormControl,
  Grid,
  Autocomplete,
  Typography,
  Box,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "../../api";
import { message } from "antd";
import EditIcon from "@mui/icons-material/Edit";

const validationSchema = Yup.object().shape({
  assest_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9 ]+$/,
      "Asset Name should only contain alphanumeric characters(no special characters)"
    ) // Allow alphanumeric characters and spaces
    .required("Asset Name is required"),
  address: Yup.string().required("Address is required"),
  emergency_contact_phone: Yup.string()
    // .required("Emergency Contact Phone is required")
    .matches(/^\d*$/, "Emergency Contact Phone must be numeric only")
    .test(
      "len",
      "Emergency Contact Phone must be 10 digits",
      (val) => !val || val.length === 10
    ),
  site_category_id: Yup.string().required("Site Category is required"),
  mine_material: Yup.string().required("Mine Material is required"),
  site_size: Yup.number().required("Site Size is required"),
  company_owned_by: Yup.string().required("Company Ownership is required"),
  owner_ship_type: Yup.string().required("Ownership type is required"),
  country_id: Yup.string().required("Country is required"),
  state_id: Yup.string().when("country_id", {
    is: "1", // value of 'India'
    then: () => Yup.string().required("State is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  district_id: Yup.string().when("country_id", {
    is: "1", // value of 'India'
    then: () => Yup.string().required("District is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  village: Yup.string().required("City/Village is required"),
  pin: Yup.string()
    .required("Pin Code is required")
    .matches(/^\d{5,6}$/, "Pin Code must be 5 or 6 digits"),
});

const AssetForm = (props) => {
  const {
    setFormVisible,
    setSelectedLocation,
    isEditMode,
    setIsEditMode,
    getAllAssestdetails,
  } = props;
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    props.tempFormData?.country_id || ""
  );
  const [selectedState, setSelectedState] = useState(
    props.tempFormData?.state_id || ""
  );
  const [states, setStates] = useState([]);
  const [district, setDisctrict] = useState([]);
  const [editOnExistingAssest, setEditingOnExistingAssest] = useState(false);
  const [fetchSiteCategories, setFetchSiteCategories] = useState([]);
  const [fetchMineMaterial, setFetchMineMaterial] = useState([]);
  const [fetchCompanyData, setFetchComapnyData] = useState([]);
  const [ownerShipData, setFetchOwnerShipData] = useState([]);

  // let showEdit = false;
  // let showDelete = false;
  // const user = useSelector((state) => state.auth.user);
  // if (user.user_role === 1) {
  //   showEdit = true;
  //   showDelete = true;
  // } else if (user?.user_role === 2) {
  //   if (user?.user_role === 2) {
  //     showEdit = true;
  //   }
  //   if (user?.user_role === 1) {
  //     showDelete = true;
  //   }
  // }

  const onClickExistingCancel = () => {
    setEditingOnExistingAssest(false);
    // setEditForm(true);
  };

  const handleassestEdit = async (assestId, values) => {
    values.geolocation = JSON.stringify(values.geolocation);
    try {
      const response = await axios.put(
        `/assests/edit-assest/${assestId}`,
        values
      );
      if (response.status === 201) {
        message.success("Asset updated successfully");
        setTimeout(() => {
          props.moveToNextTab(response.data.id[0]);
        }, 1000);
        // setIsEditMode(false); // Disable edit mode after saving
        getAllAssestdetails();
      }
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  const handleAssetDelete = async (assestId) => {
    try {
      const response = await axios.delete(`/assests/${assestId}`);
      setFormVisible(false);
      setSelectedLocation(null);
      message.success(response.data.message);
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  //Fetch Country
  const fetchCountry = async () => {
    try {
      const response = await axios.get(`/countries`);
      const { data } = response;
      setCountries(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  //Fetch Statces
  useEffect(() => {
    fetchStates();
  }, [selectedCountry]);

  const fetchStates = async () => {
    try {
      const response = await axios.get(`/states/country/${selectedCountry}`);
      const { data } = response;
      setStates(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  //Fetch district
  useEffect(() => {
    fetchDistrict();
  }, [selectedState]);

  const fetchDistrict = async () => {
    try {
      const response = await axios.get(`/districts/state/${selectedState}`);

      const { data } = response;
      setDisctrict(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const fetchSiteCategoiresData = async () => {
    try {
      const response = await axios.get("/site-categories");
      const { data } = response;
      setFetchSiteCategories(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetchSiteCategoiresData();
    fetchMineMaterialData();
    fetchCompanyProfileData();
    fetchOwnerShipData();
  }, []);
  const fetchMineMaterialData = async () => {
    try {
      const response = await axios.get("/mine-material");
      const { data } = response;
      setFetchMineMaterial(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  // useEffect(() => {

  // }, []);
  const fetchCompanyProfileData = async () => {
    try {
      const response = await axios.get("/company-profile");
      const { data } = response;
      setFetchComapnyData(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  // useEffect(() => {

  // }, []);

  const fetchOwnerShipData = async () => {
    try {
      const response = await axios.get("/owner-ship");
      const { data } = response;
      setFetchOwnerShipData(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  // useEffect(() => {

  // }, []);
  useEffect(() => {
    if (!props.tempFormData?.assest_id) {
      setIsEditMode(true);
    }
  }, [props.tempFormData?.assest_id]);
  return (
    <div className="map-form-fields">
      {isEditMode ? (
        <>
          <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={{
              assest_name: props.tempFormData?.assest_name || "",
              geolocation: props.tempFormData?.geolocation
                ? JSON.parse(props.tempFormData?.geolocation)
                : props.selectedLocationFromParent || {},
              address: props.tempFormData?.address || "",
              emergency_contact_person:
                props.tempFormData?.emergency_contact_person || "",
              emergency_contact_phone:
                props.tempFormData?.emergency_contact_phone || "",
              site_category_id: props.tempFormData?.site_category_id || "",
              mine_material: props.tempFormData?.mine_material || "",
              site_size: props.tempFormData?.site_size || "",
              company_owned_by: props.tempFormData?.company_owned_by || "",
              owner_ship_type: props.tempFormData?.owner_ship_type || "",
              country_id: props.tempFormData?.country_id || "",
              state_id: props.tempFormData?.state_id || "",
              district_id: props.tempFormData?.district_id || "",
              village: props.tempFormData?.village || "",
              pin: props.tempFormData?.pin || "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const response = await axios.post(`/assests/add-assest`, [
                  values,
                ]);
                if (response.status === 201) {
                  message.success("Asset Saved successfully");
                  setTimeout(() => {
                    props.moveToNextTab(response.data.id[0]);
                  }, 2000);
                }
                setSubmitting(false);
              } catch (error) {
                message.error(error.response.data.error);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              setFieldValue,
              values,
              touched,
              errors,
              handleSubmit,
              handleBlur,
              setFieldTouched,
              validateField,
            }) => (
              <Form className="row form-style">
                <Grid container spacing={2}>
                  {props.tempFormData?.assest_id && !isEditMode && (
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIsEditMode(true)}
                    >
                      <EditIcon />
                      <Typography variant="body2" ml={1}>
                        Edit
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          Site Name
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="assest_name"
                      value={values.assest_name}
                      //   onChange={handleChange}
                      onChange={(e) =>
                        setFieldValue("assest_name", e.target.value)
                      }
                      error={touched.assest_name && Boolean(errors.assest_name)}
                      helperText={touched.assest_name && errors.assest_name}
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Geo Location"
                      value={`${values.geolocation?.lat || ""}, ${
                        values.geolocation?.lng || ""
                      }`}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          Address
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="address"
                      value={values.address}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Emergency Contact Person"
                      name="emergency_contact_person"
                      value={values.emergency_contact_person}
                      onChange={(e) =>
                        setFieldValue(
                          "emergency_contact_person",
                          e.target.value
                        )
                      }
                      error={
                        touched.emergency_contact_person &&
                        Boolean(errors.emergency_contact_person)
                      }
                      helperText={
                        touched.emergency_contact_person &&
                        errors.emergency_contact_person
                      }
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Emergency Contact Phone"
                      name="emergency_contact_phone"
                      value={values.emergency_contact_phone}
                      onChange={(e) =>
                        setFieldValue("emergency_contact_phone", e.target.value)
                      }
                      error={
                        touched.emergency_contact_phone &&
                        Boolean(errors.emergency_contact_phone)
                      }
                      helperText={
                        touched.emergency_contact_phone &&
                        errors.emergency_contact_phone
                      }
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={!isEditMode && props.tempFormData?.assest_id}
                        select
                        name="site_category_id"
                        value={values.site_category_id}
                        label={
                          <span>
                            Site Category
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        error={
                          touched.site_category_id &&
                          Boolean(errors.site_category_id)
                        }
                        helperText={
                          touched.site_category_id && errors.site_category_id
                        }
                        onChange={(event) => {
                          setFieldValue("site_category_id", event.target.value); // Update the form field value
                        }}
                      >
                        {fetchSiteCategories
                          .filter((country) => country.is_active !== 0)
                          .map((country) => (
                            <MenuItem
                              key={country.site_category_id}
                              value={country.site_category_id}
                            >
                              {country.site_category_name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={!isEditMode && props.tempFormData?.assest_id}
                        select
                        name="mine_material"
                        value={values.mine_material}
                        label={
                          <span>
                            Mine Material
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        error={
                          touched.mine_material && Boolean(errors.mine_material)
                        }
                        helperText={
                          touched.mine_material && errors.mine_material
                        }
                        onChange={(event) => {
                          setFieldValue("mine_material", event.target.value); // Update the form field value
                        }}
                      >
                        {fetchMineMaterial
                          .filter((country) => country.is_active !== 0)
                          .map((country) => (
                            <MenuItem
                              key={country.material_id}
                              value={country.material_id}
                            >
                              {country.material_name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          Site Size
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="site_size"
                      type="number"
                      value={values.site_size}
                      onChange={(e) =>
                        setFieldValue("site_size", e.target.value)
                      }
                      error={touched.site_size && Boolean(errors.site_size)}
                      helperText={touched.site_size && errors.site_size}
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={!isEditMode && props.tempFormData?.assest_id}
                        select
                        name="company_owned_by"
                        value={values.company_owned_by}
                        label={
                          <span>
                            Company Owned By
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        error={
                          touched.company_owned_by &&
                          Boolean(errors.company_owned_by)
                        }
                        helperText={
                          touched.company_owned_by && errors.company_owned_by
                        }
                        onChange={(event) => {
                          setFieldValue("company_owned_by", event.target.value); // Update the form field value
                        }}
                      >
                        {fetchCompanyData.map((country) => (
                          <MenuItem
                            key={country.company_profile_id}
                            value={country.company_profile_id}
                          >
                            {country.company_profile_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={!isEditMode && props.tempFormData?.assest_id}
                        select
                        name="owner_ship_type"
                        value={values.owner_ship_type}
                        label={
                          <span>
                            Owner Ship
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        error={
                          touched.owner_ship_type &&
                          Boolean(errors.owner_ship_type)
                        }
                        helperText={
                          touched.owner_ship_type && errors.owner_ship_type
                        }
                        onChange={(event) => {
                          setFieldValue("owner_ship_type", event.target.value); // Update the form field value
                        }}
                      >
                        {ownerShipData.map((country) => (
                          <MenuItem
                            key={country.ownership_id}
                            value={country.ownership_id}
                          >
                            {country.ownership_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={!isEditMode && props.tempFormData?.assest_id}
                        select
                        name="country_id"
                        value={values.country_id}
                        label={
                          <span>
                            Country
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              {" "}
                              *
                            </span>
                          </span>
                        }
                        error={touched.country_id && Boolean(errors.country_id)}
                        helperText={touched.country_id && errors.country_id}
                        onChange={(event) => {
                          setSelectedCountry(event.target.value);
                          setFieldValue("country_id", event.target.value);
                        }}
                      >
                        {countries.map((country) => (
                          <MenuItem
                            key={country.country_id}
                            value={country.country_id}
                          >
                            {country.country_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Autocomplete
                      disabled={
                        !values.country_id ||
                        states.length === 0 ||
                        (!isEditMode && props.tempFormData?.assest_id)
                      }
                      id="state-autocomplete"
                      options={states}
                      getOptionLabel={(option) => option.state_name || ""}
                      value={
                        states.find(
                          (state) => state.state_id === values.state_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        const stateId = newValue ? newValue.state_id : "";
                        setSelectedState(stateId);
                        setFieldValue("state_id", stateId);

                        // Clear district and trigger validation
                        if (!stateId) {
                          setFieldValue("district_id", "");
                        } else {
                          // Set district_id to empty and trigger validation if needed
                          setFieldValue("district_id", "");
                          // Optionally trigger validation manually
                          validateField("district_id");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          error={touched.state_id && Boolean(errors.state_id)}
                          helperText={
                            (touched.state_id && errors.state_id) ||
                            (!values.country_id
                              ? "Please select a country first"
                              : "")
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6}>
                    <Autocomplete
                      disabled={
                        !values.country_id ||
                        !values.state_id ||
                        states.length === 0 ||
                        (!isEditMode && props.tempFormData?.assest_id)
                      }
                      id="district-autocomplete"
                      options={district}
                      getOptionLabel={(option) => option.district_name || ""}
                      value={
                        district.find(
                          (district) =>
                            district.district_id === values.district_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "district_id",
                          newValue ? newValue.district_id : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="District"
                          variant="outlined"
                          error={
                            touched.district_id && Boolean(errors.district_id)
                          }
                          helperText={touched.district_id && errors.district_id}
                        />
                      )}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={6} sm={6}>
                    <Autocomplete
                      disabled={
                        !values.country_id ||
                        states.length === 0 ||
                        (!isEditMode && props.tempFormData?.assest_id)
                      }
                      id="state-autocomplete"
                      options={states}
                      getOptionLabel={(option) => option.state_name || ""}
                      value={
                        states.find(
                          (state) => state.state_id === values.state_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        const stateId = newValue ? newValue.state_id : "";
                        setSelectedState(stateId);
                        setFieldValue("state_id", stateId);

                        // Clear district when state is removed
                        if (!stateId) {
                          setFieldValue("district_id", "");
                        }
                      }}
                      // onChange={(event, newValue) => {
                      //   const stateId = newValue ? newValue.state_id : "";
                      //   setSelectedState(stateId);
                      //   setFieldValue("state_id", stateId);
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          error={touched.state_id && Boolean(errors.state_id)}
                          helperText={
                            (touched.state_id && errors.state_id) ||
                            (!values.country_id
                              ? "Please select a country first"
                              : "")
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Autocomplete
                      disabled={
                        !values.country_id ||
                        states.length === 0 ||
                        (!isEditMode && props.tempFormData?.assest_id)
                      }
                      id="district-autocomplete"
                      options={district}
                      getOptionLabel={(option) => option.district_name || ""}
                      value={
                        district.find(
                          (district) =>
                            district.district_id === values.district_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "district_id",
                          newValue ? newValue.district_id : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="District"
                          variant="outlined"
                          error={
                            touched.district_id && Boolean(errors.district_id)
                          }
                          helperText={touched.district_id && errors.district_id}
                        />
                      )}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} md={6} sm={6}>
                    <Autocomplete
                      // disabled={!isEditMode && props.tempFormData?.assest_id}
                      disabled={
                        !values.country_id ||
                        states.length === 0 ||
                        (!isEditMode && props.tempFormData?.assest_id)
                      }
                      id="state-autocomplete"
                      options={district}
                      getOptionLabel={(option) => option.district_name}
                      value={
                        district.find(
                          (state) => state.district_id === values.district_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "district_id",
                          newValue ? newValue.district_id : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="District"
                          variant="outlined"
                          error={
                            touched.district_id && Boolean(errors.district_id)
                          }
                          helperText={touched.district_id && errors.district_id}
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          City/Village
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="village"
                      value={values.village}
                      onChange={(e) => setFieldValue("village", e.target.value)}
                      error={touched.village && Boolean(errors.village)}
                      helperText={touched.village && errors.village}
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          Pin Code
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="pin"
                      value={values.pin}
                      onChange={(e) => setFieldValue("pin", e.target.value)}
                      error={touched.pin && Boolean(errors.pin)}
                      helperText={touched.pin && errors.pin}
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} className="button-container" mt={3}>
                  {editOnExistingAssest && (
                    <Button onClick={onClickExistingCancel}>Cancel</Button>
                  )}
                  {props.tempFormData?.assest_id && !isEditMode && (
                    <>
                      <Button
                        variant="primary"
                        className="m-3"
                        onClick={() => setIsEditMode(true)}
                      >
                        Edit
                      </Button>

                      <Button
                        variant="danger"
                        onClick={() =>
                          handleAssetDelete(props.tempFormData?.assest_id)
                        }
                        // disabled={!editForm}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                  {isEditMode ? (
                    props.tempFormData?.assest_id ? (
                      <Button
                        variant="primary"
                        onClick={() =>
                          handleassestEdit(
                            props.tempFormData?.assest_id,
                            values
                          )
                        }
                      >
                        Save
                      </Button>
                    ) : (
                      <Button variant="primary" type="submit">
                        Save and Next
                      </Button>
                    )
                  ) : (
                    <></>
                  )}
                </Grid>
              </Form>
            )}

            {/* {showDeletePopup && <h1>fsdgtfh</h1>} */}
          </Formik>
        </>
      ) : (
        <div className="asset-details">
          {props.tempFormData?.assest_id && !isEditMode && (
            <Grid
              className="edit_btn"
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => setIsEditMode(true)}
            >
              <Typography variant="body2" ml={1}>
                <EditIcon />
                Edit
              </Typography>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Asset Name
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.assest_name
                      ? props.tempFormData?.assest_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Address
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.address}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Emergency Contact Person
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.emergency_contact_person
                      ? props.tempFormData?.emergency_contact_person
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Emergency Contact Number
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.emergency_contact_phone
                      ? props.tempFormData?.emergency_contact_phone
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Site Category
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.site_category_name
                      ? props.tempFormData?.site_category_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Mine Material
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.material_name
                      ? props.tempFormData?.material_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Site Size
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.site_size}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Company Owned By
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.company_profile_name
                      ? props.tempFormData?.company_profile_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Owner Ship Type
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.owner_ship_type
                      ? props.tempFormData?.ownership_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Country
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.country_name
                      ? props.tempFormData?.country_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {" "}
                    State
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.state_name
                      ? props.tempFormData?.state_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    District
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.district_name
                      ? props.tempFormData?.district_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Village
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.village
                      ? props.tempFormData?.village
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    Pin
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}
                  >
                    {props.tempFormData?.pin ? props.tempFormData?.pin : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};
export default AssetForm;
