import React, { useEffect, useState } from "react";
import axios from "../../api";

const ImageViewer = ({ imageUrl, onClose }) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(`/file/serve-image/${imageUrl}`, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "image/jpeg" }); // Adjust MIME type if needed
        setImageSrc(URL.createObjectURL(blob));
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [imageUrl]);

  useEffect(() => {
    if (imageSrc) {
      window.open(imageSrc, "_blank", "noopener,noreferrer");
      onClose();
    }
  }, [imageSrc, onClose]);

  return <p>Loading image...</p>;
};

export default ImageViewer;
