// import React, { useState } from "react";
// import { Document, Page } from "react-pdf";
// import { Box, Button, Typography } from "@mui/material";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// const FileViewer = ({ file }) => {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   const goToPrevPage = () => setPageNumber(pageNumber - 1);
//   const goToNextPage = () => setPageNumber(pageNumber + 1);

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         mt: 4,
//       }}
//     >
//       <Typography variant="h6">PDF Viewer</Typography>
//       <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
//         <Page pageNumber={pageNumber} />
//       </Document>
//       <Typography variant="body2">
//         Page {pageNumber} of {numPages}
//       </Typography>
//       <Box sx={{ mt: 2 }}>
//         <Button
//           variant="contained"
//           onClick={goToPrevPage}
//           disabled={pageNumber <= 1}
//           sx={{ mr: 1 }}
//         >
//           Previous
//         </Button>
//         <Button
//           variant="contained"
//           onClick={goToNextPage}
//           disabled={pageNumber >= numPages}
//         >
//           Next
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default FileViewer;
import React, { useEffect, useState } from "react";
import axios from "../../api";

const FileViewer = ({ fileUrl, onClose }) => {
  const [fileSrc, setFileSrc] = useState("");

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await axios.get(`/file/serve-zip/${fileUrl}`, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "application/zip" }); // Adjust MIME type if needed
        setFileSrc(URL.createObjectURL(blob));
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    fetchFile();
  }, [fileUrl]);

  useEffect(() => {
    if (fileSrc) {
      window.open(fileSrc, "_blank", "noopener,noreferrer");
      onClose();
    }
  }, [fileSrc, onClose]);

  return <p>Loading file...</p>;
};

export default FileViewer;
