import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
// import "./index.css";
import MUIDataTable from "mui-datatables";
import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Grid, Modal, Switch, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api";

const validationSchema = Yup.object({
  site_category_name: Yup.string()
    .min(3, "Site Category must be at least 3 characters")
    .max(50, "Site Category must be at most 20 characters")
    .matches(/^\S(?:.*\S)?$/, "Site Category must not contain empty space")
    .required("Site Category is required"),
});

function SiteCategory() {
  const [fetchSiteCategories, setFetchSiteCategories] = useState([]);
  const [editItem, setEditItem] = useState({
    site_category_id: null,
    site_category_name: "",
  });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      site_category_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSaveUser(values);
    },
  });

  const fetchSiteCategoiresData = async () => {
    try {
      const response = await axios.get("/site-categories/categories");
      const { data } = response;
      setFetchSiteCategories(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetchSiteCategoiresData();
  }, []);

  const confirmEdit = (e) => {
    fetchSiteCategoiresData();
    setShowEditModal(false);
  };

  const handleCreateUser = () => {
    setShowCreateModal(true);
  };

  const handleModalClose = () => {
    formik.resetForm();
    setShowCreateModal(false);
  };
  const handleModalEditClose = () => {
    formik.resetForm();
    setShowEditModal(false);
  };

  const handleEditUsernameChange = (event) => {
    setEditItem({ ...editItem, site_category_name: event.target.value });
  };

  const handleSaveUser = async (values) => {
    const body = {
      site_category_name: values.site_category_name,
    };

    try {
      await axios.post("/site-categories", body);
      message.success("Site category created successfully");
      formik.resetForm();
      fetchSiteCategoiresData();
    } catch (error) {
      message.error(error);
    }

    // Implement the logic to save the new user here
    setShowCreateModal(false);
  };

  const handleEditUser = async (id) => {
    setShowEditModal(true);

    try {
      const { site_category_id, ...updatedFields } = editItem;
      await axios.put(`/site-categories/cat/${id}`, updatedFields);
      setShowEditModal(false);
      message.success("Site Category updated successfully");
      fetchSiteCategoiresData();
    } catch (error) {
      setShowEditModal(true);
      message.error(error.response.data.error);
    }
    // setShowActivePopconfirm(true);
  };

  // const confirmDelete = async (id) => {
  //   try {
  //     await axios.delete(`/site-categories/delete-cat/${id}`);
  //     message.success("Site Category deleted successfully");
  //     fetchSiteCategoiresData();
  //   } catch (error) {
  //     console.error("Error details:", error);
  //     message.error(error.response.data.error);
  //   }
  // };

  const handleEdit = async (id) => {
    setShowEditModal(true);

    const editUser = fetchSiteCategories.find(
      (each) => each.site_category_id === id
    );

    const updateItem = {
      site_category_id: editUser.site_category_id,
      site_category_name: editUser.site_category_name,
    };
    setEditItem(updateItem);
  };

  const confirmDeactivate = async (rowData, isActive) => {
    const cat_id = rowData.site_category_id;
    // fetchUsersData();
    try {
      await axios.put(`/site-categories/status/${cat_id}`, {
        is_active: isActive,
      });
      fetchSiteCategoiresData();
      message.success("Status updated successfully");
    } catch (error) {
      console.error("error", error);
    }
  };
  const options = {
    selectableRows: "none", // "none" will hide checkboxes on rows
    selectableRowsHeader: false, // Set to false to hide the header checkbox
    print: false,
    download: false,
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };
  const CustomToolbar = () => {
    return (
      <button
        className="btn btn-success mb-2"
        style={{ background: "#330066" }}
        onClick={handleCreateUser}
      >
        Create Site Category
      </button>
    );
  };
  const columns = [
    {
      name: "site_category_name",
      label: "Site Category Name",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        // customBodyRender: (value) => capitalizeFirstLetter(value),
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              inputProps={{ "aria-label": "controlled" }}
              // Add onChange handler to handle status change
              onChange={(event) =>
                confirmDeactivate(data, event.target.checked)
              }
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <div className="actions-wrapper">
              <Button
                style={{ marginRight: "2px" }}
                onClick={() => handleEdit(data?.site_category_id)}
              >
                <Tooltip
                  title="Edit User"
                  PopperProps={{
                    style: { fontSize: "1.8rem" }, // Adjust the font size as needed
                  }}
                >
                  <EditIcon color="primary" />
                </Tooltip>
              </Button>
              {/* <Popconfirm
                title="Delete the site category"
                description="Are you sure to delete this site category?"
                onConfirm={() => confirmDelete(data.site_category_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: "2px" }}>
                  <Tooltip title="Delete User">
                    <DeleteIcon color="error" />
                  </Tooltip>
                </Button>
              </Popconfirm> */}
              {/* <Button onClick={() => resetPassword(data.user_email)}>
                <Tooltip title="Reset Password">
                  <LockResetIcon Password color="primary" />
                </Tooltip>
              </Button> */}
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      {/* <div
        // className="mt-5"
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          className="btn btn-success mb-2"
          style={{ background: "#330066" }}
          onClick={handleCreateUser}
        >
          Create Site Category
        </button>
      </div> */}
      {showCreateModal && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          fullWidth
          title={<h5 className="modal-title">Create User</h5>}
          // title={"Site Category"}
          open={showCreateModal}
          closable={false}
        >
          <Box className="new-site-category">
            {/* Title */}
            <h5 id="modal-title" className="modal-title">
              Add new site category
            </h5>
            <form className=" form" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="site_category_name"
                    name="site_category_name"
                    label="Site Category"
                    value={formik.values.site_category_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.site_category_name &&
                      Boolean(formik.errors.site_category_name)
                    }
                    helperText={
                      formik.touched.site_category_name &&
                      formik.errors.site_category_name
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <button
                    style={{
                      position: "relative",
                      borderColor: "#2196f3",
                      color: "#2196f3",
                    }}
                    type="button"
                    className="btn"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    style={{
                      position: "relative",
                      backgroundColor: "#2196f3",
                      marginLeft: 2.5,
                    }}
                    // onClick={handleSaveUser}
                  >
                    Create
                  </button>
                </Box>
              </Grid>
            </form>
          </Box>
        </Modal>
      )}
      {showEditModal && (
        <Modal
          open={showEditModal}
          okButtonProps={{
            style: { display: "none" },
          }}
          cancelButtonProps={{
            style: { display: "none" },
          }}
          closable={false}
        >
          <Box className="edit-category-popup">
            {/* Title */}
            <h5 id="modal-title" className="modal-title">
              Edit site category
            </h5>
            <form className="form" onSubmit={confirmEdit}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="site_category_name"
                    name="site_category_name"
                    label="Site Category Name"
                    value={editItem.site_category_name}
                    onChange={handleEditUsernameChange}
                    error={
                      formik.touched.site_category_name &&
                      Boolean(formik.errors.site_category_name)
                    }
                    helperText={
                      formik.touched.site_category_name &&
                      formik.errors.site_category_name
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <button
                    style={{
                      position: "relative",
                      borderColor: "#2196f3",
                      color: "#2196f3",
                    }}
                    type="button"
                    className="btn"
                    onClick={handleModalEditClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    style={{
                      position: "relative",
                      backgroundColor: "#2196f3",
                      marginLeft: 2.5,
                    }}
                    onClick={() => handleEditUser(editItem.site_category_id)}
                  >
                    Save
                  </button>
                </Box>
              </Grid>
            </form>
          </Box>
        </Modal>
      )}
      <MUIDataTable
        className="m-super-admin"
        title={"Site Categories"}
        data={fetchSiteCategories}
        columns={columns}
        options={options}
      />
    </>
  );
}
export default SiteCategory;
