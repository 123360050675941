import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import "./index.css";
import { CgProfile } from "react-icons/cg";
import axios from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../store/reducers/auth";
import { message } from "antd";
const Navbar = () => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const location = useLocation();

  const resetPassword = async (email) => {
    try {
      const response = await axios.post(`/auth/forgot-password`, {
        email,
      });
      if (response.status === 200) {
        message.success("Email is sent successfully!");
      }
    } catch (error) {
      console.error(error.response.data.error);
      message.error({ submit: error.response.data.error || error.message });
    }
  };

  // const links = [
  //   { path: "/map", label: "Map" },
  //   { path: "/admin", label: "Admin" },
  //   { path: "/documentlist", label: "Document List" },
  // ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  let showAdmin = false;

  if (user?.user_role === 1) {
    showAdmin = true;
  }

  const toggleProfileMenu = (event) => {
    event.stopPropagation();
    setProfileMenuOpen(!profileMenuOpen);
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      setProfileMenuOpen(false);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    setProfileMenuOpen(false); // Close the profile menu when the location changes
  }, [location]);

  const handleLogout = async () => {
    await axios.get("/auth/logout");
    localStorage.removeItem("token");
    dispatch(userLogout({ user: null }));
    navigate("/");
    window.location.reload();
  };
  return (
    <nav className="navbar" onClick={() => setProfileMenuOpen(false)}>
      <div className="navbar-logo">
        <Link to="/map">
          <img src={`${process.env.PUBLIC_URL}/company_logo.png`} alt="logo" />
        </Link>
      </div>
      <div className={`navbar-buttons ${isOpen ? "open" : ""}`}>
        <NavLink
          key="map"
          to="/map"
          className={({ isActive }) => `nav-button ${isActive ? "active" : ""}`}
          onClick={() => setIsOpen(false)}
        >
          Map View
        </NavLink>
        <NavLink
          key="ListView"
          to="/ListView"
          className={({ isActive }) => `nav-button ${isActive ? "active" : ""}`}
          onClick={() => setIsOpen(false)}
        >
          Asset List
        </NavLink>
        {showAdmin && (
          <NavLink
            key="admin"
            to="/admin"
            className={({ isActive }) =>
              `nav-button ${isActive ? "active" : ""}`
            }
            onClick={() => setIsOpen(false)}
          >
            User Management
          </NavLink>
        )}
        {showAdmin && (
          <NavLink
            key="Settings"
            to="/Settings"
            className={({ isActive }) =>
              `nav-button ${isActive ? "active" : ""}`
            }
            onClick={() => setIsOpen(false)}
          >
            Settings
          </NavLink>
        )}
        <NavLink
          key="doumentlist"
          to="/documentlist"
          className={({ isActive }) => `nav-button ${isActive ? "active" : ""}`}
          onClick={() => setIsOpen(false)}
        >
          Documents Expiry Report
        </NavLink>
        <div className="profile-menu" onClick={(e) => e.stopPropagation()}>
          <button
            className="profile-icon text-white"
            onClick={toggleProfileMenu}
          >
            <CgProfile className="icon" />
          </button>
          {profileMenuOpen && (
            <div className="profile-submenu">
              <NavLink
                // to="/changepassword"
                onClick={() => resetPassword(user?.user_email)}
              >
                Change Password
              </NavLink>
              <NavLink
                to="/"
                onClick={() => {
                  setProfileMenuOpen(false);
                  handleLogout();
                }}
              >
                Logout
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <button className="navbar-toggle" onClick={toggleMenu}>
        ☰
      </button>
    </nav>
  );
};

export default Navbar;
