/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import MUIDataTable from "mui-datatables";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";

const validationSchema = Yup.object({
  company_profile_name: Yup.string()
    .min(3, "Company profile name must be at least 3 characters")
    .max(50, "Company profile name must be at most 20 characters")
    .matches(
      /^\S(?:.*\S)?$/,
      "Company profile name must not contain empty space"
    )
    .required("Company profile name is required"),
  address: Yup.string().required("Address is required"),
  country_id: Yup.string().required("Country is required"),
  state_id: Yup.string().when("country_id", {
    is: "1", // value of 'India'
    then: () => Yup.string().required("State is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  district_id: Yup.string().when("country_id", {
    is: "1", // value of 'India'
    then: () => Yup.string().required("District is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  pin_code: Yup.string()
    .required("Pin Code is required")
    .matches(/^\d{5,6}$/, "Pin Code must be 5 or 6 digits"),
});

function CompanyProfile() {
  const [fetchCompanyData, setFetchComapnyData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const [states, setStates] = useState([]);
  const [district, setDisctrict] = useState([]);
  const [editItem, setEditItem] = useState({
    company_profile_id: null,
    company_profile_name: "",
    address: "",
    country_id: "",
    state_id: "",
    district_id: "",
    pin_code: "",
  });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      company_profile_name: "",
      address: "",
      country_id: "",
      state_id: "",
      district_id: "",
      pin_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSaveUser(values);
    },
  });

  const fetchCompanyProfileData = async () => {
    try {
      const response = await axios.get("/company-profile/company");
      const { data } = response;
      setFetchComapnyData(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetchCompanyProfileData();
  }, []);

  const confirmEdit = (e) => {
    fetchCompanyProfileData();
    setShowEditModal(false);
  };

  const handleCreateUser = () => {
    setShowCreateModal(true);
  };

  const handleModalClose = () => {
    formik.resetForm();
    setShowCreateModal(false);
  };
  const handleModalEditClose = () => {
    formik.resetForm();
    setShowEditModal(false);
  };

  const handleSaveUser = async (values) => {
    const body = {
      company_profile_name: values.company_profile_name,
      address: values.address,
      district_id: values.district_id || null,
      country_id: values.country_id,
      state_id: values.state_id || null,
      pin_code: values.pin_code,
    };

    try {
      await axios.post("/company-profile", body);
      message.success("Company profile created successfully");
      formik.resetForm();
      fetchCompanyProfileData();
    } catch (error) {
      message.error(error);
    }

    // Implement the logic to save the new user here
    setShowCreateModal(false);
  };

  const handleEditUser = async (id) => {
    setShowEditModal(true);

    try {
      const { company_profile_id, ...updatedFields } = editItem;
      await axios.put(`/company-profile/comapny/${id}`, updatedFields);
      setShowEditModal(false);
      message.success("Company Profile updated successfully");
      fetchCompanyProfileData();
    } catch (error) {
      setShowEditModal(true);
      message.error(error.response.data.error);
    }
    // setShowActivePopconfirm(true);
  };

  // const confirmDelete = async (id) => {
  //   try {
  //     await axios.delete(`/company-profile/delete-comapny/${id}`);
  //     message.success("Company Profile deleted successfully");
  //     fetchCompanyProfileData();
  //   } catch (error) {
  //     console.error("Error details:", error);
  //     message.error(error.response.data.error);
  //   }
  // };

  const handleEdit = async (id) => {
    const editUser = fetchCompanyData.find(
      (each) => each.company_profile_id === id
    );

    const updateItem = {
      company_profile_id: editUser.company_profile_id,
      company_profile_name: editUser.company_profile_name,
      address: editUser.address,
      district_id: editUser.district_id,
      country_id: editUser.country_id,
      state_id: editUser.state_id,
      pin_code: editUser.pin_code,
    };

    setEditItem(updateItem);
    setSelectedCountry(editUser.country_id);
    setSelectedState(editUser.state_id);
    setSelectedDistrict(editUser.district_id);

    // Fetch the dependent data
    fetchStates(editUser.country_id);
    fetchDistrict(editUser.state_id);

    setShowEditModal(true);
  };

  // const handleEdit = async (id) => {
  //   setShowEditModal(true);

  //   const editUser = fetchCompanyData.find(
  //     (each) => each.company_profile_id === id
  //   );

  //   const updateItem = {
  //     company_profile_id: editUser.company_profile_id,
  //     company_profile_name: editUser.company_profile_name,
  //     address: editUser.address,
  //     district_id: editUser.district_id,
  //     country_id: editUser.country_id,
  //     state_id: editUser.state_id,
  //     pin_code: editUser.pin_code,
  //   };
  //   setEditItem(updateItem);
  // };
  const confirmDeactivate = async (rowData, isActive) => {
    const cat_id = rowData.company_profile_id;
    // fetchUsersData();
    try {
      await axios.put(`/company-profile/status/${cat_id}`, {
        is_active: isActive,
      });
      message.success("Status updated successfully");
      fetchCompanyProfileData();
    } catch (error) {
      console.error("error", error);
    }
  };
  const options = {
    selectableRows: "none",
    selectableRowsHeader: false,
    print: false,
    download: false,
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };
  const CustomToolbar = () => {
    return (
      <button
        className="btn btn-success mb-2"
        style={{ background: "#330066" }}
        onClick={handleCreateUser}
      >
        Create company profile
      </button>
    );
  };
  const columns = [
    {
      name: "company_profile_name",
      label: "Company profile Name",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        // customBodyRender: (value) => capitalizeFirstLetter(value),
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        // customBodyRender: (value) => capitalizeFirstLetter(value),
      },
    },
    {
      name: "country_name",
      label: "Country",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        // customBodyRender: (value) => capitalizeFirstLetter(value),
      },
    },
    {
      name: "state_name",
      label: "State",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        // customBodyRender: (value) => capitalizeFirstLetter(value),
      },
    },
    {
      name: "district_name",
      label: "District",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        // customBodyRender: (value) => capitalizeFirstLetter(value),
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              inputProps={{ "aria-label": "controlled" }}
              // Add onChange handler to handle status change
              onChange={(event) =>
                confirmDeactivate(data, event.target.checked)
              }
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <div className="actions-wrapper">
              <Button
                style={{ marginRight: "2px" }}
                onClick={() => handleEdit(data?.company_profile_id)}
              >
                <Tooltip
                  title="Edit User"
                  PopperProps={{
                    style: { fontSize: "1.8rem" }, // Adjust the font size as needed
                  }}
                >
                  <EditIcon color="primary" />
                </Tooltip>
              </Button>
              {/* <Popconfirm
                title="Delete the Company Profile"
                description="Are you sure to delete this Company Profile?"
                onConfirm={() => confirmDelete(data.company_profile_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: "2px" }}>
                  <Tooltip title="Delete User">
                    <DeleteIcon color="error" />
                  </Tooltip>
                </Button>
              </Popconfirm> */}
              {/* <Button onClick={() => resetPassword(data.user_email)}>
                <Tooltip title="Reset Password">
                  <LockResetIcon Password color="primary" />
                </Tooltip>
              </Button> */}
            </div>
          );
        },
      },
    },
  ];

  //Fetch Country
  const fetchCountry = async () => {
    try {
      const response = await axios.get(`/countries`);
      const { data } = response;
      setCountries(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  //Fetch Statces
  useEffect(() => {
    // Fetch states when country changes
    if (editItem.country_id) {
      fetchStates(editItem.country_id);
    } else {
      fetchStates();
    }
  }, [editItem.country_id, selectedCountry]);

  useEffect(() => {
    // Fetch districts when state changes
    if (editItem.state_id) {
      fetchDistrict(editItem.state_id);
    } else {
      fetchDistrict();
    }
  }, [editItem.state_id, selectedState]);

  const fetchStates = async () => {
    try {
      const response = await axios.get(`/states/country/${selectedCountry}`);
      const { data } = response;
      setStates(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  // //Fetch district
  // useEffect(() => {
  //   fetchDistrict();
  // }, [selectedState]);

  const fetchDistrict = async () => {
    try {
      const response = await axios.get(`/districts/state/${selectedState}`);

      const { data } = response;
      setDisctrict(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  console.log(editItem);

  return (
    <>
      {showCreateModal && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          fullWidth
          open={showCreateModal}
          closable={false}
        >
          <Box className="new-site-category">
            {/* Title */}
            <h5 id="modal-title" className="modal-title">
              Add new company
            </h5>
            <form className=" form" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    fullWidth
                    id="company_profile_name"
                    name="company_profile_name"
                    label="Company Profile Name"
                    value={formik.values.company_profile_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.company_profile_name &&
                      Boolean(formik.errors.company_profile_name)
                    }
                    helperText={
                      formik.touched.company_profile_name &&
                      formik.errors.company_profile_name
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      name="country_id"
                      value={formik.values.country_id}
                      label="Country"
                      error={
                        formik.touched.country_id &&
                        Boolean(formik.errors.country_id)
                      }
                      helperText={
                        formik.touched.country_id && formik.errors.country_id
                      }
                      onChange={(event) => {
                        setSelectedCountry(event.target.value);
                        formik.setFieldValue("country_id", event.target.value); // Update the form field value
                      }}
                    >
                      {countries.map((country) => (
                        <MenuItem
                          key={country.country_id}
                          value={country.country_id}
                        >
                          {country.country_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Autocomplete
                    id="state-autocomplete"
                    options={states}
                    getOptionLabel={(option) => option.state_name}
                    value={
                      states.find(
                        (state) => state.state_id === formik.values.state_id
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setSelectedState(newValue.state_id);
                      formik.setFieldValue(
                        "state_id",
                        newValue ? newValue.state_id : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        variant="outlined"
                        error={
                          formik.touched.state_id &&
                          Boolean(formik.errors.state_id)
                        }
                        helperText={
                          formik.touched.state_id && formik.errors.state_id
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Autocomplete
                    id="state-autocomplete"
                    options={district}
                    getOptionLabel={(option) => option.district_name}
                    value={
                      district.find(
                        (state) =>
                          state.district_id === formik.values.district_id
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setSelectedDistrict(newValue.district_id);
                      formik.setFieldValue(
                        "district_id",
                        newValue ? newValue.district_id : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="District"
                        variant="outlined"
                        error={
                          formik.touched.district_id &&
                          Boolean(formik.errors.district_id)
                        }
                        helperText={
                          formik.touched.district_id &&
                          formik.errors.district_id
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    fullWidth
                    id="pin_code"
                    name="pin_code"
                    label="Pin Code"
                    value={formik.values.pin_code}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.pin_code && Boolean(formik.errors.pin_code)
                    }
                    helperText={
                      formik.touched.pin_code && formik.errors.pin_code
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <button
                    style={{
                      position: "relative",
                      borderColor: "#2196f3",
                      color: "#2196f3",
                    }}
                    type="button"
                    className="btn"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    style={{
                      position: "relative",
                      backgroundColor: "#2196f3",
                      marginLeft: 2.5,
                    }}
                    // onClick={handleSaveUser}
                  >
                    Create
                  </button>
                </Box>
              </Grid>
            </form>
          </Box>
        </Modal>
      )}
      {showEditModal && (
        <Modal
          open={showEditModal}
          okButtonProps={{
            style: { display: "none" },
          }}
          cancelButtonProps={{
            style: { display: "none" },
          }}
          closable={false}
        >
          <Box className="edit-category-popup">
            {/* Title */}
            <h5 id="modal-title" className="modal-title">
              Edit company profile
            </h5>
            <form className="form" onSubmit={confirmEdit}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    fullWidth
                    id="company_profile_name"
                    name="company_profile_name"
                    label="Company profile Name"
                    value={editItem.company_profile_name}
                    onChange={(e) =>
                      setEditItem({
                        ...editItem,
                        company_profile_name: e.target.value,
                      })
                    }
                    error={
                      formik.touched.company_profile_name &&
                      Boolean(formik.errors.company_profile_name)
                    }
                    helperText={
                      formik.touched.company_profile_name &&
                      formik.errors.company_profile_name
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    value={editItem.address}
                    onChange={(e) =>
                      setEditItem({ ...editItem, address: e.target.value })
                    }
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={countries}
                      getOptionLabel={(option) => option.country_name}
                      value={
                        editItem.country_id
                          ? countries.find(
                              (c) => c.country_id === editItem.country_id
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        setEditItem({
                          ...editItem,
                          country_id: newValue?.country_id || "",
                        });
                        setSelectedCountry(newValue?.country_id || "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={states}
                      getOptionLabel={(option) => option.state_name}
                      value={
                        editItem.state_id
                          ? states.find((s) => s.state_id === editItem.state_id)
                          : null
                      }
                      onChange={(event, newValue) => {
                        setEditItem({
                          ...editItem,
                          state_id: newValue?.state_id || "",
                        });
                        setSelectedState(newValue?.state_id || "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="State" />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={district}
                      getOptionLabel={(option) => option.district_name}
                      value={
                        // {console.loog(editItem.district_id)}
                        editItem.district_id
                          ? district.find(
                              (d) => d.district_id === editItem.district_id
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        setEditItem({
                          ...editItem,
                          district_id: newValue?.district_id || "",
                        });
                        setSelectedDistrict(newValue.district_id || "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="District" />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    fullWidth
                    id="pin_code"
                    name="pin_code"
                    label="Pin Code"
                    value={editItem.pin_code}
                    onChange={(e) =>
                      setEditItem({ ...editItem, pin_code: e.target.value })
                    }
                    error={
                      formik.touched.pin_code && Boolean(formik.errors.pin_code)
                    }
                    helperText={
                      formik.touched.pin_code && formik.errors.pin_code
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <button
                    style={{
                      position: "relative",
                      borderColor: "#2196f3",
                      color: "#2196f3",
                    }}
                    type="button"
                    className="btn"
                    onClick={handleModalEditClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    style={{
                      position: "relative",
                      backgroundColor: "#2196f3",
                      marginLeft: 2.5,
                    }}
                    onClick={() => handleEditUser(editItem.company_profile_id)}
                  >
                    Save
                  </button>
                </Box>
              </Grid>
            </form>
          </Box>
        </Modal>
      )}
      <MUIDataTable
        className="m-super-admin"
        title={"Comapny Profiles"}
        data={fetchCompanyData}
        columns={columns}
        options={options}
      />
      {/* </Container> */}
    </>
  );
}
export default CompanyProfile;
