import React from "react";
import BackgroundVideo from "../Login/BackgroundVideo";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";
import {
  Box,
  Card,
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "../../api";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { message } from "antd";
const videoSources = [
  "/login1.mp4",
  "/login2.mp4",
  "/login3.mp4",
  "/login4.mp4",
  "/login5.mp4",
  "/login6.mp4",
];
const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password is too short - should be 6 chars minimum.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    )
    .test(
      "no-spaces",
      "Password cannot contain spaces",
      (value) => !/\s/.test(value)
    )
    .required("Please enter new password"),
  repeat_password: Yup.string()
    .min(6, "Password is too short - should be 6 chars minimum.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    )
    .test(
      "no-spaces",
      "Password cannot contain spaces",
      (value) => !/\s/.test(value)
    )
    .required("Please enter confirm password"),
});
const fallbackImage = `${process.env.PUBLIC_URL}/loginbackground2.jpg`;
const PasswordChange = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  return (
    <>
      <div className="login">
        <BackgroundVideo
          videoSources={videoSources}
          fallbackImage={fallbackImage}
        />
        <div className="logo" style={{ margin: "10px 10px 10px 10px" }}>
          <img src={`${process.env.PUBLIC_URL}/company_logo.png`} alt="logo" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
            marginBottom: "50px",
            width: "100%",
          }}
        >
          <Card className="signin-form signin-admin-form">
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  mb: { xs: 2 },
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Forgot Your Password?
              </Box>
              <Box sx={{ mb: 5, fontSize: 10 }}>
                <Typography component="p">
                  Tell us your email and we will send your password.
                </Typography>
              </Box>
              <Formik
                validationSchema={validationSchema}
                validateOnChange={true}
                initialValues={{
                  password: "",
                  repeat_password: "",
                }}
                // validationSchema={validationSchema}
                onSubmit={async (
                  data,
                  { setErrors, resetForm, setSubmitting }
                ) => {
                  try {
                    if (data.password !== data.repeat_password) {
                      setErrors({
                        repeat_password: "Password doesnt match",
                      });
                      setSubmitting(false);
                    } else {
                      // Send a request to reset the password
                      const response = await axios.post(
                        `/auth/reset-password/${token}`,
                        {
                          password: data.password,
                          repeat_password: data.repeat_password,
                        }
                      );
                      if (response.status === 200) {
                        message.success("Password Reset Succesfully!");
                        resetForm();
                        setTimeout(() => {
                          navigate("/");
                        }, 5000); // 3000 milliseconds equals 3 seconds
                      }
                    }
                  } catch (error) {
                    console.error(error.response.data.error);
                    setErrors({
                      submit: error.response.data.error || error.message,
                    });
                  }
                }}
              >
                {({
                  isSubmitting,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange} // Make sure Formik handles this
                          onBlur={handleBlur} // Make sure Formik handles this too
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          label="Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Repeat Password"
                          name="repeat_password"
                          value={values.repeat_password}
                          onChange={handleChange} // Make sure Formik handles this
                          onBlur={handleBlur} // Make sure Formik handles this too
                          error={
                            touched.repeat_password &&
                            Boolean(errors.repeat_password)
                          }
                          helperText={
                            touched.repeat_password && errors.repeat_password
                          }
                          label="Confirm New Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>

                    {errors.submit && (
                      <Grid
                        item
                        mt={5}
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FormHelperText sx={{ fontSize: "17px" }} error>
                          {errors.submit}
                        </FormHelperText>
                      </Grid>
                    )}

                    <Box mt={4} mb={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          //   width: "100%",
                          height: 44,
                        }}
                        type="submit"
                      >
                        Reset My Password
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>

              <img
                src={`${process.env.PUBLIC_URL}/applogo.png`}
                alt="App Logo"
                className="app-logo"
              />
            </Grid>
          </Card>
        </div>
      </div>
    </>
  );
};

export default PasswordChange;
