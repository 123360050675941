import React from "react";
import "primeicons/primeicons.css";

const Popup = ({ message = "Please select location", onClose }) => {
  return (
    <div style={styles.popupContainer}>
      <div style={styles.popupContent}>
        <div style={styles.messageContainer}>
          <p style={styles.messageText}>{message}</p>
        </div>
        <button onClick={onClose} style={styles.okButton}>
          OK
        </button>
      </div>
    </div>
  );
};

const styles = {
  popupContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popupContent: {
    background: "white",
    padding: "30px",
    borderRadius: "10px",
    textAlign: "center",
    position: "relative",
    width: "300px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  messageContainer: {
    marginBottom: "20px",
  },
  messageText: {
    margin: 0,
    fontSize: "18px",
    fontWeight: "bold",
  },
  okButton: {
    padding: "5px 10px",
    border: "none",
    background: "#007BFF",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
  },
};

export default Popup;
