import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./index.css"
const NotFound = () => {
    const navigate=useNavigate()
  return (
    <div className='not-found'>
      <h3 className='text-danger mb-2'>Something went wrong</h3>
      <button type="button" className='btn btn-primary' onClick={() => navigate("/")}>Login</button>
    </div>
  )
}

export default NotFound
