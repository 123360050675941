import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Admin from "./components/Admin";
import PasswordChange from "./components/PasswordChange";
import ForgotPassword from "./components/ForgotPassword";
import MapWithForm from "./components/MapWithForm";
import NotFound from "./components/NotFound";
import DocumentList from "./components/Documents";
// import Navbar from "./components/Navbar/index.js";
import Footer from "./components/Footer";
import Navbar from "./components/NavBar";
import ListView from "./components/ListView";
import { userLogin } from "../src/components/store/reducers/auth";
import { useSelector, useDispatch } from "react-redux";
import axios, { setAuthToken } from "../src/api";
import PageLoader from "./components/PageLoader";
import PdfPasswordPage from "./components/UploadedDocuments/PdfPasswordPage";
import Settings from "./components/Settings";
import FileAccess from "./components/FileAccess";
const App = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        dispatch(userLogin({ user: undefined }));
        setLoading(false);
        return;
      }

      setAuthToken(token);
      axios
        .get("/auth")
        .then((response) => {
          dispatch(userLogin({ user: response.data }));
          setLoading(false);
        })
        .catch((error) => {
          dispatch(userLogin({ user: undefined }));
          setLoading(false);
        });
    };

    getAuthUser();
  }, [dispatch]);

  const location = useLocation();
  const shouldHideNavbar =
    location.pathname === "/" ||
    location.pathname === "/forgotpassword" ||
    /^\/changepassword\/[^/]+$/.test(location.pathname); // Regex to match '/changepassword/:token'

  if (loading) {
    return <PageLoader />;
  }
  return (
    <>
      {!shouldHideNavbar && <Navbar />}
      <Routes>
        {user ? (
          <>
            <Route exact path="/map" element={<MapWithForm />} />
            {user.user_role === 1 ? (
              <>
                <Route exact path="/admin" element={<Admin />} />
                <Route exact path="/Settings" element={<Settings />} />
              </>
            ) : (
              <Route path="*" element={<NotFound />} />
            )}
            <Route exact path="/ListView" element={<ListView />} />
            <Route exact path="/documentlist" element={<DocumentList />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
        <Route
          exact
          path="/"
          element={user ? <Navigate to="/map" /> : <Login />}
        />
        <Route
          exact
          path="/changepassword/:token"
          element={<PasswordChange />}
        />
        <Route exact path="/view-file/:token" element={<FileAccess />} />
        <Route exact path="/forgotpassword" element={<ForgotPassword />} />
        <Route exact path="/filepassword" element={<PdfPasswordPage />} />
        <Route exact path="/documentlist" element={<DocumentList />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
export default AppWrapper;
