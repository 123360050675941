import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css"; // Import Bootstrap CSS
import "./index.css";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./components/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
);
