
import React, { useRef, useEffect, useState } from "react";
import "./BackgroundVideo.css";

const BackgroundVideo = ({ videoSources, fallbackImage }) => {
	const videoRefs = useRef([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [videoError, setVideoError] = useState(false);

	useEffect(() => {
		const handleVideoEnd = () => {
			setCurrentIndex(
				(prevIndex) => (prevIndex + 1) % videoSources.length
			);
		};

		const currentVideo = videoRefs.current[currentIndex];
		if (currentVideo) {
			currentVideo.addEventListener("ended", handleVideoEnd);
			currentVideo.addEventListener("error", () => setVideoError(true));
			return () => {
				currentVideo.removeEventListener("ended", handleVideoEnd);
				currentVideo.removeEventListener("error", () =>
					setVideoError(true)
				);
			};
		}
	}, [currentIndex, videoSources.length]);

	return (
		<div className="video-background">
			{videoError ? (
				<img
					src={fallbackImage}
					alt="Fallback"
					className="fallback-image"
				/>
			) : (
				videoSources.map((src, index) => (
					<video
						key={index}
						ref={(el) => (videoRefs.current[index] = el)}
						className={`background-video ${
							index === currentIndex ? "show" : ""
						}`}
						autoPlay
						muted
					>
						<source src={src} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				))
			)}
		</div>
	);
};

export default BackgroundVideo;
