// PdfViewer.js
import React, { useEffect, useState } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";

// Set up the worker URL to the local file or CDN
import axios from "../../api";
// Set the workerSrc property to point to the PDF.js worker script

const PdfViewer = ({ pdfId, onClose }) => {
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(`/file/serve-pdf/${pdfId}`, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "application/pdf" });

        setPdfUrl(URL.createObjectURL(blob));
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    fetchPdf();
  }, [pdfId]);

  return (
    <div>
      {pdfUrl ? (
        (window.open(pdfUrl, "noopener,noreferrer"), onClose())
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default PdfViewer;
