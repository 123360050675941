import React, { useState } from "react";
import {
  Modal as MUIModal,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  Switch,
  MenuItem,
} from "@mui/material";
import { Autocomplete, Chip } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import axios from "../../api";
import { message } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

// Define a custom validation method for the email array
const validationSchema = Yup.object({
  to: Yup.array()
    .of(Yup.string().email("Invalid email format"))
    .nullable()
    .optional(),
  cc: Yup.array()
    .of(Yup.string().email("Invalid email format"))
    .nullable()
    .optional(),
});

const cronValidationSchema = Yup.object({
  to: Yup.array()
    .of(Yup.string().email("Invalid email format"))
    .nullable()
    .optional(),
  cc: Yup.array()
    .of(Yup.string().email("Invalid email format"))
    .nullable()
    .optional(),
  frequency_type: Yup.string()
    .required("Required")
    .oneOf(["weekly", "custom"], "Invalid frequency type"),
  day_of_week: Yup.string().when("frequency_type", {
    is: "weekly", // value of 'India'
    then: () => Yup.string().required("Please select a day of the week"),
    otherwise: () => Yup.string().notRequired(),
  }),
  custom_days: Yup.number().when("frequency_type", {
    is: "custom",
    then: () => Yup.number().required("Custom interval days are required"),
    otherwise: () => Yup.number().notRequired(),
  }),
  time_of_day: Yup.string()
    .required("Time of day is required")
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Time must be in HH:MM format"),
});

const EmailModal = ({ open, onClose }) => {
  const [isCronEnabled, setIsCronEnabled] = useState(false);
  const convertToUTC = (time) => {
    const [hours, minutes] = time.split(":");
    const localTime = dayjs()
      .hour(hours)
      .minute(minutes)
      .second(0)
      .millisecond(0);
    const utcTime = localTime.utc().format("HH:mm");
    return utcTime;
  };

  return (
    <div className="map-form-fields">
      <Container fluid>
        <MUIModal open={open} onClose={onClose}>
          <Box className="edit_doc">
            <Typography
              variant="h6"
              component="h2"
              sx={{ marginBottom: "10px" }}
            >
              Send Email
            </Typography>
            <Formik
              initialValues={{
                to: [],
                cc: [],
                cron_enabled: false,
                frequency_type: "",
                day_of_week: "",
                custom_days: "",
                time_of_day: "",
              }}
              validationSchema={
                isCronEnabled ? cronValidationSchema : validationSchema
              }
              onSubmit={async (values) => {
                // Convert time_of_day to UTC if cron is enabled
                const timeOfDayUTC = values.cron_enabled
                  ? convertToUTC(values.time_of_day)
                  : null;

                // Prepare data for API
                const emailData = [
                  ...values.to.map((email) => ({ email, type: "to" })),
                  ...values.cc.map((email) => ({ email, type: "cc" })),
                ];

                try {
                  // Send email data
                  if (emailData.length > 0) {
                    await axios.post("/sendmail", {
                      emails: emailData,
                    });
                  }

                  // Save cron configuration if enabled
                  if (values.cron_enabled) {
                    await axios.post("/configureCron", {
                      cron_enabled: values.cron_enabled,
                      frequency_type: values.frequency_type || null,
                      day_of_week: values.day_of_week || null,
                      custom_days: values.custom_days || null,
                      time_of_day: timeOfDayUTC || null,
                    });
                  }

                  message.success("Email will be sent successfully");
                  onClose(); // Close the modal after submission
                } catch (error) {
                  message.error(error?.response?.data?.error);
                  console.error("Error sending emails:", error);
                }
              }}
            >
              {({
                values,
                setFieldValue,
                errors,
                touched,
                resetForm,
                isSubmitting,
              }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12}>
                      <FormControl fullWidth>
                        <Typography variant="subtitle1">
                          Enable Cron Job:
                        </Typography>
                        <Switch
                          checked={values.cron_enabled}
                          onChange={() => {
                            setIsCronEnabled(!isCronEnabled); // Toggle the isCronEnabled state
                            setFieldValue("cron_enabled", !values.cron_enabled); // Update the formik value for cron_enabled
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} sm={12}>
                      <Autocomplete
                        multiple
                        freeSolo
                        name="to"
                        options={[]} // No options needed for freeSolo mode
                        value={values.to}
                        onChange={(event, newValue) =>
                          setFieldValue("to", newValue)
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option}
                              {...getTagProps({ index })}
                              key={index}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Primary Recipients"
                            helperText={
                              touched.to && errors.to ? errors.to : ""
                            }
                            error={touched.to && Boolean(errors.to)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <Autocomplete
                        multiple
                        freeSolo
                        name="cc"
                        options={[]} // No options needed for freeSolo mode
                        value={values.cc}
                        onChange={(event, newValue) =>
                          setFieldValue("cc", newValue)
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option}
                              {...getTagProps({ index })}
                              key={index}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Additional Recipients (CC)"
                            helperText={
                              touched.cc && errors.cc ? errors.cc : ""
                            }
                            error={touched.cc && Boolean(errors.cc)}
                          />
                        )}
                      />
                    </Grid>

                    {values.cron_enabled && (
                      <>
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            select
                            fullWidth
                            name="frequency_type"
                            value={values.frequency_type}
                            label="Frequency"
                            error={
                              touched.frequency_type &&
                              Boolean(errors.frequency_type)
                            }
                            helperText={
                              touched.frequency_type && errors.frequency_type
                            }
                            onChange={(e) =>
                              setFieldValue("frequency_type", e.target.value)
                            }
                          >
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="custom">Custom Interval</MenuItem>
                          </TextField>
                        </Grid>

                        {values.frequency_type === "weekly" && (
                          <Grid item xs={12} md={6} sm={6}>
                            <TextField
                              select
                              fullWidth
                              id="dayOfWeek"
                              name="day_of_week"
                              value={values.day_of_week}
                              onChange={(e) =>
                                setFieldValue("day_of_week", e.target.value)
                              }
                              label="Day of the Week"
                              error={
                                touched.day_of_week &&
                                Boolean(errors.day_of_week)
                              }
                              helperText={
                                touched.day_of_week && errors.day_of_week
                              }
                            >
                              <MenuItem value="Monday">Monday</MenuItem>
                              <MenuItem value="Tuesday">Tuesday</MenuItem>
                              <MenuItem value="Wednesday">Wednesday</MenuItem>
                              <MenuItem value="Thursday">Thursday</MenuItem>
                              <MenuItem value="Friday">Friday</MenuItem>
                              <MenuItem value="Saturday">Saturday</MenuItem>
                              <MenuItem value="Sunday">Sunday</MenuItem>
                            </TextField>
                          </Grid>
                        )}

                        {values.frequency_type === "custom" && (
                          <Grid item xs={12} md={6} sm={6}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Interval (Days)"
                              name="custom_days"
                              value={values.custom_days}
                              onChange={(e) =>
                                setFieldValue("custom_days", e.target.value)
                              }
                              helperText={
                                touched.custom_days && errors.custom_days
                                  ? errors.custom_days
                                  : ""
                              }
                              error={
                                touched.custom_days &&
                                Boolean(errors.custom_days)
                              }
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={6} sm={6}>
                          <FormControl fullWidth>
                            <Field
                              label="Time"
                              name="time_of_day"
                              as={TextField}
                              type="time"
                              fullWidth
                              value={values.time_of_day}
                              onChange={(e) =>
                                setFieldValue("time_of_day", e.target.value)
                              }
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              error={errors.time_of_day && touched.time_of_day}
                              helperText={
                                touched.time_of_day && errors.time_of_day
                              }
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}

                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      item
                      xs={12}
                      md={12}
                      sm={12}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ marginRight: "10px" }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Send"}
                      </Button>
                      <Button
                        onClick={() => {
                          resetForm();
                          onClose();
                        }}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </MUIModal>
      </Container>
    </div>
  );
};

export default EmailModal;
