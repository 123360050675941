/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { renderToStaticMarkup } from "react-dom/server";
import DocumentForm from "../DocumentForm/index.js";
import "react-tabs/style/react-tabs.css";
import Button from "react-bootstrap/Button";
import AssetForm from "../AssetForm.js";
import UploadedDocuments from "../UploadedDocuments/index.js";
import "./index.css";
import { GiStarFlag } from "react-icons/gi";
import { Box, Tab, Tabs } from "@mui/material";
import axios from "../../api";
import { useSelector } from "react-redux";
import Popup from "../Popup.js";
import AppLoader from "../AppLoader/index.js";

const MapWithForm = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  const user = useSelector((state) => state.auth.user);
  const defaultCenter = { lat: 17.465900519060896, lng: 78.29023892319296 };
  const [searchInput, setSearchInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(
    localStorage.getItem("assetId") ? true : false
  );
  const [tabIndex, setTabIndex] = useState(0);
  const [enableDocumentTab, setEnableDocumentTab] = useState(true);
  const [assetId, setAssetId] = useState(
    localStorage.getItem("assetId") || null
  );
  const [assetObj, setAssetObj] = useState({});
  const [documentObj, setDocumentObj] = useState({});
  const [searchBox, setSearchBox] = useState(null);
  const [allAssestData, setAllAssestData] = useState([]);
  const [allAssestDataId, setAllAssestDataId] = useState([]);
  const [openedFormData, setOpenedFormData] = useState({});
  const [assestEditMode, setAssestEditMode] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const mode = localStorage.getItem("mode");

  const mapIsEditMode = mode === "edit";
  const [isEditMode, setIsEditMode] = useState(mapIsEditMode);
  let showAddAssest = false;

  if (user?.user_role === 3) {
    showAddAssest = false;
  } else if (user?.user_role === 1) {
    showAddAssest = true;
  } else if (user?.user_role === 2) {
    if (user?.user_permission === "Add") {
      showAddAssest = true;
    }
  }
  const [markers, setMarkers] = useState([]);
  useEffect(() => {
    getAllAssestdetails();
  }, [assetObj, formVisible]);

  const getAllAssestdetails = async () => {
    try {
      const response = await axios.get(`/assests`);
      const { data } = response;
      setAllAssestData(data.list);
      const filteringMapLocations = data?.list?.map((each) => {
        const { lat, lng } = JSON.parse(each?.geolocation);
        let position = { lat: Number(lat), lng: Number(lng) };
        let newItem = { assest_name: each.assest_name, position };
        return newItem;
      });
      setMarkers(filteringMapLocations);
    } catch (error) {
      setAllAssestData([]);
    }
  };

  useEffect(() => {
    getAllAssestdetailsId();
  }, [assetId]);

  const getAllAssestdetailsId = async () => {
    try {
      const response = await axios.get(`/assests/${assetId}`);
      const { data } = response;
      await setAllAssestDataId(data.list);
      if (assetId) {
        setLoading(true);
      }
      setTimeout(() => {
        handleMarkerClick(data.list);
      }, 3000);
      // handleMarkerClick();
      // const filteringMapLocations = data?.list?.map((each) => {
      //   const { lat, lng } = JSON.parse(each?.geolocation);
      //   let position = { lat: Number(lat), lng: Number(lng) };
      //   let newItem = { assest_name: each.assest_name, position };
      //   return newItem;
      // });
      // setMarkers(filteringMapLocations);
    } catch (error) {
      setAllAssestDataId([]);
    }
  };

  const getIconDataUrl = (iconComponent) => {
    const svgString = renderToStaticMarkup(iconComponent);
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
  };

  const customIcon = {
    url: getIconDataUrl(<GiStarFlag size={30} color="red" />),

    // scaledSize: new window.google.maps.Size(40, 40),
    // origin: new window.google.maps.Point(0, 0),
    // anchor: new window.google.maps.Point(20, 40),
  };

  const mapStyles = {
    height: "90vh",
    width: "100%",
  };

  const handlePlacesChanged = () => {
    setSelectedLocation({
      lat: searchBox.getPlaces()[0].geometry.location.lat(),
      lng: searchBox.getPlaces()[0].geometry.location.lng(),
    });
    // setFormVisible(true);
  };

  const handleLoad = (ref) => {
    setSearchBox(ref);
  };

  const handleDataFromDocumentChild = (data) => {
    setDocumentObj(data);
  };

  const handleDataFromAssetChild = (data) => {
    const localMarker = {
      position: { lat: selectedLocation.lat, lng: selectedLocation.lng },
      name: data.name,
    };
    setMarkers((prevMarkers) => [...prevMarkers, localMarker]);
    setAssetId(data.assest_id);
    setAssetObj(data);
    setEnableDocumentTab(false);
    handleFormVisible(1);
  };
  const handleMapClick = (event) => {
    if (formVisible) {
      // console.log("inside 1");
      return;
    } else {
      // console.log("inside 2");
      setSelectedLocation({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      // setFormVisible(true);
      localStorage.setItem("documentTempData", "");
    }
  };

  const handleFormVisible = (value) => {
    setTabIndex(value);
  };

  const handleButtonClick = () => {
    selectedLocation !== null && setFormVisible(true);
    if (selectedLocation === null) {
      setPopupMessage("Please select location");
      return;
    } else {
      setFormVisible(true);
      setAssestEditMode(false);
      localStorage.setItem("documentTempData", "");
    }
  };

  const handleMarkerClick = (event) => {
    setLoading(false);
    let position;
    let longitude;
    let latitude;
    let filteringMarkerData;
    const assestStoredID = localStorage.getItem("assetId");
    if (assestStoredID) {
      if (event?.geolocation) {
        try {
          const { lat, lng } = JSON.parse(event.geolocation);
          longitude = lng;
          latitude = lat;
          position = { lat: lat, lng: lng };
          filteringMarkerData = event;
        } catch (error) {
          console.error("Invalid JSON in geolocation:", error);
          return;
        }
      } else {
        console.error("geolocation is undefined in event");
        return;
      }
    } else {
      position = { lat: event?.latLng?.lat(), lng: event?.latLng?.lng() };
      filteringMarkerData = allAssestData.find((each) => {
        if (each?.geolocation) {
          try {
            const { lat, lng } = JSON.parse(each.geolocation);
            let oldposition = { lat: Number(lat), lng: Number(lng) };
            if (JSON.stringify(oldposition) === JSON.stringify(position)) {
              return each;
            }
          } catch (error) {
            console.error("Invalid JSON in geolocation:", error);
          }
        }
        return undefined;
      });
    }

    if (filteringMarkerData !== undefined) {
      setOpenedFormData(filteringMarkerData);
      setFormVisible(true);
      setSelectedLocation({
        lat: assestStoredID ? latitude : event.latLng.lat(),
        lng: assestStoredID ? longitude : event.latLng.lng(),
      });
      setAssestEditMode(true);
    }

    localStorage.removeItem("assetId");
    localStorage.removeItem("mode");
  };

  // const handleMarkerClick = (event) => {
  //   setLoading(false);
  //   let position;
  //   let longitude;
  //   let latitude;
  //   let filteringMarkerData;
  //   const assestStoredID = localStorage.getItem("assetId");
  //   if (assestStoredID) {
  //     const { lat, lng } = JSON.parse(event?.geolocation);
  //     longitude = lng;
  //     latitude = lat;
  //     position = { lat: lat, lng: lng };
  //     filteringMarkerData = event;
  //   } else {
  //     position = { lat: event?.latLng?.lat(), lng: event?.latLng?.lng() };
  //     filteringMarkerData = allAssestData.find((each) => {
  //       const { lat, lng } = JSON.parse(each?.geolocation);
  //       let oldposition = { lat: Number(lat), lng: Number(lng) };
  //       if (JSON.stringify(oldposition) === JSON.stringify(position)) {
  //         return each;
  //       }
  //       return undefined;
  //     });
  //   }

  //   if (filteringMarkerData !== undefined) {
  //     setOpenedFormData(filteringMarkerData);
  //     setFormVisible(true);
  //     setSelectedLocation({
  //       lat: assestStoredID ? latitude : event.latLng.lat(),
  //       lng: assestStoredID ? longitude : event.latLng.lng(),
  //     });
  //     setAssestEditMode(true);
  //   }
  //   localStorage.removeItem("assetId");
  //   localStorage.removeItem("mode");
  // };
  const formCloseOnDelete = () => {
    setFormVisible(false);
  };
  const handleNextTab = (id) => {
    setAssetId(id);
    setTabIndex((prevIndex) => prevIndex + 1);
  };
  const handlePassDataTab = (id) => {
    setAssetId(id);
  };
  const [documentsData, setDocumentsData] = useState(null);

  const fetchDocuments = async () => {
    try {
      const assetIdToUse = !isEditMode ? openedFormData?.assest_id : assetId;

      if (assetIdToUse) {
        // Ensure the ID is not null or undefined
        const response = await axios.get(`/documents/assest/${assetIdToUse}`);
        const { data } = response;
        setDocumentsData(data.list);
      } else {
        console.warn("No valid asset ID provided");
      }
    } catch (error) {
      console.error("Error fetching documents", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [isEditMode, assetId, openedFormData?.assest_id]);

  const handlePopupClose = () => {
    setPopupMessage("");
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={selectedLocation ? selectedLocation : defaultCenter}
          onClick={handleMapClick}
        >
          {!formVisible && (
            <div style={{ display: "flex" }}>
              <div className="serach-box">
                <StandaloneSearchBox
                  onLoad={handleLoad}
                  onPlacesChanged={handlePlacesChanged}
                >
                  <input
                    type="search"
                    placeholder="Search Google Maps"
                    className="search-input"
                    // ref={searchBoxRef}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </StandaloneSearchBox>
                {showAddAssest && (
                  <Button
                    onClick={handleButtonClick}
                    variant="success"
                    disabled={formVisible}
                  >
                    Add Asset
                  </Button>
                )}
              </div>
            </div>
          )}
          {selectedLocation && showAddAssest && (
            <Marker position={selectedLocation} icon={customIcon} />
          )}

          {/* markers not filteringMapLocations */}
          {markers?.map((marker, index) => {
            return (
              <Marker
                key={index}
                position={marker?.position}
                // onClick={handleMapClick}
                onClick={(e) => handleMarkerClick(e)}
                // onClick={() => setSelectedLocation(marker.position)}
                icon={customIcon}
                label={{
                  text: marker.assest_name,
                  className: "marker-label",
                }}
              />
            );
          })}
          {formVisible && selectedLocation && (
            <InfoWindow
              position={selectedLocation}
              onCloseClick={() => {
                setFormVisible(false);
                setSelectedLocation(null);
                setIsEditMode(false);
                setTabIndex(0);
                localStorage.removeItem("assetId");
                localStorage.removeItem("mode"); // Clear mode on close
              }}
            >
              <Box className="map-form">
                <Tabs
                  value={tabIndex}
                  onChange={(e, newIndex) => setTabIndex(newIndex)}
                >
                  <Tab label="Asset" />
                  {isEditMode && <Tab label="Upload New Document" />}
                  <Tab label="Uploaded Documents" />
                </Tabs>

                {tabIndex === 0 && (
                  <AssetForm
                    sendDataToParent={handleDataFromAssetChild}
                    tempFormData={assestEditMode ? openedFormData : assetObj}
                    selectedLocationFromParent={selectedLocation}
                    formInEditMode={assestEditMode}
                    currentTabIndex={tabIndex}
                    formCloseOnDelete={formCloseOnDelete}
                    moveToNextTab={handleNextTab}
                    passToNextTab={handlePassDataTab}
                    setFormVisible={setFormVisible}
                    setSelectedLocation={setSelectedLocation}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    mapIsEditMode={mapIsEditMode}
                    getAllAssestdetails={getAllAssestdetails}
                  />
                )}
                {isEditMode && tabIndex === 1 && (
                  <DocumentForm
                    sendDataToParent={handleDataFromDocumentChild}
                    tempFormData={assestEditMode ? openedFormData : assetObj}
                    assetId={assetId}
                    currentTabIndex={tabIndex && assetId}
                    moveToNextTab={handleNextTab}
                    passToNextTab={handlePassDataTab}
                    fetchDocuments={fetchDocuments}
                  />
                )}
                {((isEditMode && tabIndex === 2) ||
                  (!isEditMode && tabIndex === 1)) && (
                  <UploadedDocuments
                    sendDataToParent={handleDataFromDocumentChild}
                    tempFormData={documentObj}
                    assetId={assetId}
                    currentTabIndex={tabIndex}
                    documentsData={documentsData}
                    fetchDocuments={fetchDocuments}
                  />
                )}
              </Box>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
      {loading && <AppLoader />}
      {popupMessage && (
        <Popup message={popupMessage} onClose={handlePopupClose} />
      )}
    </>
  );
};

export default MapWithForm;
