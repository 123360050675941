import {
  Box,
  Grid,
  Modal as MUIModal,
  TextField,
  Typography,
  Button as MuiButton,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { message } from "antd";
import axios from "../../api";
import PdfViewer from "./PdfViewer";
import ImageViewer from "./ImageViewer"; // Import the ImageViewer component
import FileViewer from "./FileViewer"; // Import the FileViewer component
import DocViewer from "./DocxViewer";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666",
  },
};

const ViewDocument = ({ isOpen, onClose, document }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);

  const handleCheckPassword = async () => {
    try {
      const response = await axios.post(
        `/users/is-valid-password/${document?.created_by}`,
        { password }
      );
      setIsValidPassword(response.data.isValid);
    } catch (error) {
      message.error(error.response.data.error || "An error occurred");
    }
  };

  const renderContent = () => {
    if (document?.file_name.endsWith(".pdf")) {
      return <PdfViewer pdfId={document?.file_name} onClose={onClose} />;
    } else if (
      document?.file_name.endsWith(".jpeg") ||
      document?.file_name.endsWith(".jpg") ||
      document?.file_name.endsWith(".png")
    ) {
      return <ImageViewer imageUrl={document?.file_name} onClose={onClose} />;
    } else if (document?.file_name.endsWith(".zip")) {
      return <FileViewer fileUrl={document?.file_name} onClose={onClose} />;
    } else if (
      document?.file_name.endsWith(".docx") ||
      document?.file_name.endsWith(".xlsx")
    ) {
      return <DocViewer fileUrl={document?.file_name} onClose={onClose} />;
    } else {
      return <Typography>Unsupported file type</Typography>;
    }
  };

  return (
    <MUIModal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          maxWidth: 500,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        {!isValidPassword ? (
          <>
            <Typography
              variant="h6"
              component="h2"
              sx={{ marginBottom: "10px" }}
            >
              Password
            </Typography>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Box mt={4} mb={1}>
              <MuiButton
                variant="contained"
                color="primary"
                onClick={handleCheckPassword}
                disabled={!password}
                sx={{
                  minWidth: 160,
                  fontSize: 16,
                  textTransform: "capitalize",
                  padding: "8px 16px 8px",
                }}
              >
                Enter
              </MuiButton>
            </Box>
          </>
        ) : (
          renderContent()
        )}
      </Box>
    </MUIModal>
  );
};

export default ViewDocument;
