import React from "react";
import { useNavigate } from "react-router-dom";
import axios, { setAuthToken } from "../../api";
import { useDispatch } from "react-redux";
import { userLogin } from "../store/reducers/auth";
import {
  Button,
  Card,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  FormHelperText,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid } from "@mui/material";
import BackgroundVideo from "./BackgroundVideo";
const videoSources = [
  "/login1.mp4",
  "/login2.mp4",
  "/login3.mp4",
  "/login4.mp4",
  "/login5.mp4",
  "/login6.mp4",
];
const fallbackImage = `${process.env.PUBLIC_URL}/loginbackground2.jpg`;
function Login() {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email format"
      )
      .typeError("Enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum.")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
        "Password must contain at least one letter, one number, and one special character"
      ),
  });

  const handleLogin = async (values, { setErrors, setSubmitting }) => {
    try {
      const response = await axios.post(`/auth/admin/login`, {
        email: values.email,
        password: values.password,
      });
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        setAuthToken(response.data.token);
        const user = await axios.get("/auth");
        dispatch(userLogin({ user: user.data }));
        navigate("/map");
      }
    } catch (error) {
      console.error(error.response.data.error);
      setErrors({ submit: error.response.data.error || error.message });
    } finally {
      setSubmitting(false);
    }
  };
  const handleForgotPassword = () => {
    navigate("/forgotpassword");
  };

  return (
    <div className="login">
      <BackgroundVideo
        videoSources={videoSources}
        fallbackImage={fallbackImage}
      />
      <div className="logo" style={{ margin: "10px 10px 10px 10px" }}>
        <img src={`${process.env.PUBLIC_URL}/company_logo.png`} alt="logo" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Card className="signin-form signin-admin-form">
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                mb: { xs: 3, xl: 4, fontWeight: "bold" },
                fontSize: 20,
              }}
            >
              Sign In
            </Box>
            <Formik
              validateOnChange={true}
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
            >
              {({
                isSubmitting,
                touched,
                errors,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
              }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={values.email}
                        onChange={(e) => setFieldValue("email", e.target.value)}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange} // Make sure Formik handles this
                        onBlur={handleBlur} // Make sure Formik handles this too
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        label="Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  {errors.submit && (
                    <Grid
                      item
                      mt={5}
                      xs={12}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormHelperText sx={{ fontSize: "17px" }} error>
                        {errors.submit}
                      </FormHelperText>
                    </Grid>
                  )}

                  <Box mt={4} mb={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      sx={{
                        minWidth: 160,
                        fontSize: 16,
                        textTransform: "capitalize",
                        padding: "8px 16px 8px",
                      }}
                    >
                      Sign In
                    </Button>
                  </Box>

                  <Box
                    m={2}
                    component="span"
                    sx={{
                      cursor: "pointer",
                      display: "block",
                      textAlign: "right",
                      color: "primary.main",
                      fontWeight: "bold",
                    }}
                    onClick={handleForgotPassword}
                  >
                    Forgot Password?
                  </Box>

                  {/* Sign In Button */}

                  <img
                    src={`${process.env.PUBLIC_URL}/applogo.png`}
                    alt="App Logo"
                    className="app-logo"
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default Login;
