/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tabs, Tab, Box, TextField, Button } from "@mui/material";
import "react-tabs/style/react-tabs.css";
import "./index.css";
import dateFormat from "dateformat";
import MUIDataTable from "mui-datatables";
import axios from "../../api";
import EmailModal from "./EmailModal";

const DocumentList = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [expiredDocuments, setExpiredDocuments] = useState([]);
  const [expiringDocuments, setExpiringDocuments] = useState([]);
  const [toDateFilter, setToDateFilter] = useState("");
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleFromDateFilter = (selectedValue) => {
    setFromDateFilter(selectedValue);
  };

  const handleToDateFilter = (selectedValue) => {
    setToDateFilter(selectedValue);
  };

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(`/documents`, {
        params: {
          fromDate: fromDateFilter,
          toDate: toDateFilter,
        },
      });
      const { data } = response;
      const docData = data.list;
      const expiredDocs = docData.filter(
        (doc) => new Date(doc.document_renewal_date) < new Date()
      );
      const expiringDocs = docData.filter(
        (doc) => new Date(doc.document_renewal_date) >= new Date()
      );
      setExpiredDocuments(expiredDocs);
      setExpiringDocuments(expiringDocs);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [fromDateFilter, toDateFilter]);

  const options = {
    responsive: "standard",
    selectableRows: "none",
    selectableRowsHeader: false,
    print: false,
    download: false,
    rowsPerPageOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50], // Add 15 to the options
    rowsPerPage: 30,
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };

  const CustomToolbar = () => {
    return (
      <button
        variant="contained"
        className="btn btn-success mb-2"
        style={{ background: "#330066" }}
        onClick={() => setShowModal(true)}
      >
        Send Email to
      </button>
    );
  };

  const columns = [
    {
      name: "assest_name",
      label: "Site Name",
      options: {
        sort: true,
      },
    },
    {
      name: "geolocation", // Hidden column for geolocation
      label: "Geolocation",
      options: {
        display: false, // Hide this column from the table view
      },
    },
    {
      name: "document_type",
      label: "Document Type",
      options: {
        sort: true,
      },
    },
    {
      name: "date_of_doc",
      label: "Date Of Document",
      options: {
        sort: true,
        customBodyRender: (value) => dateFormat(new Date(value), "yyyy-mm-dd"),
      },
    },
    {
      name: "document_number",
      label: "Document Number",
      options: {
        sort: true,
      },
    },
    {
      name: "document_renewal_date",
      label: "Document Renewal Date",
      options: {
        sort: true,
        customBodyRender: (value) => {
          if (!value) {
            return "N/A"; // Or any other placeholder for null or undefined values
          }
          return dateFormat(new Date(value), "yyyy-mm-dd");
        },
      },
    },
  ];
  const handleClearFilters = () => {
    setFromDateFilter("");
    setToDateFilter("");
  };
  return (
    <>
      <div className="doc-filters">
        <div className="doc-filter-fields">
          <TextField
            type="date"
            label="Filter From Date"
            value={fromDateFilter}
            onChange={(e) => handleFromDateFilter(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{
              width: "130px",
              height: "30px",
              fontSize: "0.55rem",
              "& input": {
                padding: "0.5rem",
              },
              "& .MuiInputLabel-root": {
                fontSize: "0.85rem",
              },
            }}
          />
          <TextField
            type="date"
            label="Filter To Date"
            value={toDateFilter}
            onChange={(e) => handleToDateFilter(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{
              width: "130px",
              height: "30px",
              fontSize: "0.55rem",
              "& input": {
                padding: "0.5rem",
              },
              "& .MuiInputLabel-root": {
                fontSize: "0.85rem",
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleClearFilters}
            sx={{
              height: "40px",
              fontSize: "0.85rem",
              padding: "0.5rem 1rem",
            }}
          >
            Clear
          </Button>
        </div>
      </div>
      <Container fluid>
        <Box className="exp-docs">
          <Tabs
            value={tabIndex}
            onChange={(event, newIndex) => {
              setTabIndex(newIndex);
            }}
            indicatorColor="primary"
            textColor="primary"
            aria-label="document tabs"
          >
            <Tab label="Expired Documents" />
            <Tab label="Documents Going to Expire" />
          </Tabs>
          <Box>
            {tabIndex === 0 && (
              <MUIDataTable
                title="Expired Documents"
                data={expiredDocuments}
                columns={columns}
                options={options}
              />
            )}
            {tabIndex === 1 && (
              <MUIDataTable
                title="Expiring Documents"
                data={expiringDocuments}
                columns={columns}
                options={options}
              />
            )}
          </Box>
        </Box>
      </Container>
      <EmailModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default DocumentList;
