import React from "react";
import BackgroundVideo from "../Login/BackgroundVideo";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Box, Card, FormHelperText, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { Button } from "@mui/material";
import * as Yup from "yup";
import { Typography } from "@mui/material";
import axios from "../../api";
import { message } from "antd";
const videoSources = [
  "/login1.mp4",
  "/login2.mp4",
  "/login3.mp4",
  "/login4.mp4",
  "/login5.mp4",
  "/login6.mp4",
];
const fallbackImage = `${process.env.PUBLIC_URL}/loginbackground2.jpg`;
const validationSchema = Yup.object({
  email: Yup.string()
    .email("The Email you entered is not a valid format!")
    .required("Please enter email address"),
});
const ForgotPassword = () => {
  const navigate = useNavigate();

  const forgotPassword = async (values, { setErrors, setSubmitting }) => {
    try {
      const response = await axios.post(`/auth/forgot-password`, {
        email: values.email,
      });
      if (response.status === 200) {
        message.success("Sent mail successfully!");
      }
    } catch (error) {
      console.error(error.response.data.error);
      setErrors({ submit: error.response.data.error || error.message });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="login">
        <BackgroundVideo
          videoSources={videoSources}
          fallbackImage={fallbackImage}
        />
        <div className="logo" style={{ margin: "10px 10px 10px 10px" }}>
          <img src={`${process.env.PUBLIC_URL}/company_logo.png`} alt="logo" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
            marginBottom: "50px",
            width: "100%",
          }}
        >
          <Card className="signin-form signin-admin-form">
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  mb: { xs: 2 },
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Forgot Your Password?
              </Box>
              <Box sx={{ mb: 5, fontSize: 10 }}>
                <Typography component="p">
                  Tell us your email and we will send your password.
                </Typography>
              </Box>
              <Formik
                validateOnChange={true}
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchema}
                onSubmit={forgotPassword}
              >
                {({
                  isSubmitting,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email"
                          value={values.email}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                    </Grid>

                    {errors.submit && (
                      <Grid
                        item
                        mt={5}
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FormHelperText sx={{ fontSize: "17px" }} error>
                          {errors.submit}
                        </FormHelperText>
                      </Grid>
                    )}

                    <Box mt={4} mb={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        sx={{
                          //   width: "100%",
                          height: 44,
                        }}
                        type="submit"
                      >
                        Send password
                      </Button>
                    </Box>

                    {/* Sign In Button */}
                  </Form>
                )}
              </Formik>
              <Box
                sx={{
                  mt: { xs: 3, xl: 4 },
                  mb: 2,
                  textAlign: "center",
                  color: "gray",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                <Box component="span" sx={{ mr: 1 }}>
                  Already have account?
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/")}
                >
                  Sign in here
                </Box>
              </Box>
              <img
                src={`${process.env.PUBLIC_URL}/applogo.png`}
                alt="App Logo"
                className="app-logo"
              />
            </Grid>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
