import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import MUIDataTable from "mui-datatables";
import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Grid, Modal, Switch, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api";

const validationSchema = Yup.object({
  material_name: Yup.string()
    .min(3, "Mine material must be at least 3 characters")
    .max(50, "Mine material must be at most 20 characters")
    .matches(/^\S(?:.*\S)?$/, "Mine material must not contain empty space")
    .required("Mine material is required"),
});

function MineMaterial() {
  const [fetchMineMaterial, setFetchMineMaterial] = useState([]);
  const [editItem, setEditItem] = useState({
    material_id: null,
    material_name: "",
  });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      material_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSaveUser(values);
    },
  });

  const fetchMineMaterialData = async () => {
    try {
      const response = await axios.get("/mine-material/materials");
      const { data } = response;
      setFetchMineMaterial(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetchMineMaterialData();
  }, []);

  const confirmEdit = (e) => {
    fetchMineMaterialData();
    setShowEditModal(false);
  };

  const handleCreateUser = () => {
    setShowCreateModal(true);
  };

  const handleModalClose = () => {
    formik.resetForm();
    setShowCreateModal(false);
  };
  const handleModalEditClose = () => {
    formik.resetForm();
    setShowEditModal(false);
  };

  const handleEditUsernameChange = (event) => {
    setEditItem({ ...editItem, material_name: event.target.value });
  };

  const handleSaveUser = async (values) => {
    const body = {
      material_name: values.material_name,
    };

    try {
      await axios.post("/mine-material", body);
      message.success("Mine Material created successfully");
      formik.resetForm();
      fetchMineMaterialData();
    } catch (error) {
      message.error(error);
    }

    // Implement the logic to save the new user here
    setShowCreateModal(false);
  };

  const handleEditUser = async (id) => {
    setShowEditModal(true);

    try {
      const { material_id, ...updatedFields } = editItem;
      await axios.put(`/mine-material/mine/${id}`, updatedFields);
      setShowEditModal(false);
      message.success("Mine material updated successfully");
      fetchMineMaterialData();
    } catch (error) {
      setShowEditModal(true);
      message.error(error.response.data.error);
    }
    // setShowActivePopconfirm(true);
  };

  // const confirmDelete = async (id) => {
  //   try {
  //     await axios.delete(`/mine-material/delete-mine/${id}`);
  //     message.success("Mine material deleted successfully");
  //     fetchMineMaterialData();
  //   } catch (error) {
  //     console.error("Error details:", error);
  //     message.error(error.response.data.error);
  //   }
  // };

  const handleEdit = async (id) => {
    setShowEditModal(true);

    const editUser = fetchMineMaterial.find((each) => each.material_id === id);

    const updateItem = {
      material_id: editUser.material_id,
      material_name: editUser.material_name,
    };
    setEditItem(updateItem);
  };
  const confirmDeactivate = async (rowData, isActive) => {
    const material_id = rowData.material_id;
    try {
      await axios.put(`/mine-material/status/${material_id}`, {
        is_active: isActive,
      });
      fetchMineMaterialData();
      message.success("Status updated successfully");
    } catch (error) {
      console.error("error", error);
    }
  };
  const options = {
    selectableRows: "none",
    selectableRowsHeader: false,
    print: false,
    download: false,
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };
  const CustomToolbar = () => {
    return (
      <button
        className="btn btn-success mb-2"
        style={{ background: "#330066" }}
        onClick={handleCreateUser}
      >
        Create Mine material
      </button>
    );
  };
  const columns = [
    {
      name: "material_name",
      label: "Mine material Name",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        // customBodyRender: (value) => capitalizeFirstLetter(value),
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              inputProps={{ "aria-label": "controlled" }}
              // Add onChange handler to handle status change
              onChange={(event) =>
                confirmDeactivate(data, event.target.checked)
              }
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <div className="actions-wrapper">
              <Button
                style={{ marginRight: "2px" }}
                onClick={() => handleEdit(data?.material_id)}
              >
                <Tooltip
                  title="Edit User"
                  PopperProps={{
                    style: { fontSize: "1.8rem" }, // Adjust the font size as needed
                  }}
                >
                  <EditIcon color="primary" />
                </Tooltip>
              </Button>
              {/* <Popconfirm
                title="Delete the Mine Material"
                description="Are you sure to delete this Mine Material?"
                onConfirm={() => confirmDelete(data.material_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: "2px" }}>
                  <Tooltip title="Delete User">
                    <DeleteIcon color="error" />
                  </Tooltip>
                </Button>
              </Popconfirm> */}
              {/* <Button onClick={() => resetPassword(data.user_email)}>
                <Tooltip title="Reset Password">
                  <LockResetIcon Password color="primary" />
                </Tooltip>
              </Button> */}
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      {/* <Container fluid> */}
      {/* <div
        // className="mt-5"
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          className="btn btn-success mb-2"
          style={{ background: "#330066" }}
          onClick={handleCreateUser}
        >
          Create Mine material
        </button>
      </div> */}
      {showCreateModal && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          fullWidth
          title=<h5 className="modal-title">Create User</h5>
          // title={"Mine material"}
          open={showCreateModal}
          closable={false}
        >
          <Box className="new-site-category">
            {/* Title */}
            <h5 id="modal-title" className="modal-title">
              Add new mine material
            </h5>
            <form className=" form" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="material_name"
                    name="material_name"
                    label="Mine material"
                    value={formik.values.material_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.material_name &&
                      Boolean(formik.errors.material_name)
                    }
                    helperText={
                      formik.touched.material_name &&
                      formik.errors.material_name
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <button
                    style={{
                      position: "relative",
                      borderColor: "#2196f3",
                      color: "#2196f3",
                    }}
                    type="button"
                    className="btn"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    style={{
                      position: "relative",
                      backgroundColor: "#2196f3",
                      marginLeft: 2.5,
                    }}
                    // onClick={handleSaveUser}
                  >
                    Create
                  </button>
                </Box>
              </Grid>
            </form>
          </Box>
        </Modal>
      )}
      {showEditModal && (
        <Modal
          open={showEditModal}
          okButtonProps={{
            style: { display: "none" },
          }}
          cancelButtonProps={{
            style: { display: "none" },
          }}
          closable={false}
        >
          <Box className="edit-category-popup">
            {/* Title */}
            <h5 id="modal-title" className="modal-title">
              Edit mine material
            </h5>
            <form className="form" onSubmit={confirmEdit}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="material_name"
                    name="material_name"
                    label="Mine material Name"
                    value={editItem.material_name}
                    onChange={handleEditUsernameChange}
                    error={
                      formik.touched.material_name &&
                      Boolean(formik.errors.material_name)
                    }
                    helperText={
                      formik.touched.material_name &&
                      formik.errors.material_name
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <button
                    style={{
                      position: "relative",
                      borderColor: "#2196f3",
                      color: "#2196f3",
                    }}
                    type="button"
                    className="btn"
                    onClick={handleModalEditClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    style={{
                      position: "relative",
                      backgroundColor: "#2196f3",
                      marginLeft: 2.5,
                    }}
                    onClick={() => handleEditUser(editItem.material_id)}
                  >
                    Save
                  </button>
                </Box>
              </Grid>
            </form>
          </Box>
        </Modal>
      )}
      <MUIDataTable
        className="m-super-admin"
        title={"Mine Materials"}
        data={fetchMineMaterial}
        columns={columns}
        options={options}
      />
      {/* </Container> */}
    </>
  );
}
export default MineMaterial;
