// PasswordPage.jsx
import React, { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import axios from "../../api";
import { useLocation } from "react-router-dom";

const PdfPasswordPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [password, setPassword] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSubmit = async () => {
    // Handle form submission here
    try {
      const response = await axios.post("/file/verify-password", {
        token: token,
        password: password,
      });
      const blob = new Blob([response.data], { type: "application/pdf" });
      setPdfUrl(URL.createObjectURL(blob));
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 2,
      }}
    >
      <Box
        sx={{
          maxWidth: 400, // Limits the width of the form
          width: "100%", // Ensures the form takes full width within maxWidth
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Enter Password
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          autoComplete="current-password"
          autoFocus
        />
        {password && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{ marginTop: 2 }}
          >
            Enter
          </Button>
        )}
        {pdfUrl && window.open(pdfUrl, "_blank", "noopener,noreferrer")}
      </Box>
    </Box>
  );
};

export default PdfPasswordPage;
