import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tabs, Tab, Box } from "@mui/material";
import "react-tabs/style/react-tabs.css";
import SiteCategory from "../SiteCategory";
import MineMaterial from "../MineMaterial";
import CompanyProfile from "../CompanyProfile";
import OwnerShip from "../OwnerShip";

const Settings = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <Container fluid>
        <Box className="settings mt-3">
          <Tabs
            value={tabIndex}
            onChange={(event, newIndex) => {
              setTabIndex(newIndex);
            }}
            indicatorColor="primary"
            textColor="primary"
            aria-label="document tabs"
          >
            <Tab label="Site Category" />
            <Tab label="Mine Material" />
            <Tab label="Companies" />
            <Tab label="Ownership" />
          </Tabs>
          <Box>
            {tabIndex === 0 && <SiteCategory title="Site Category" />}
            {tabIndex === 1 && <MineMaterial title="Mine Material" />}
            {tabIndex === 2 && <CompanyProfile title="Companies" />}
            {tabIndex === 3 && <OwnerShip title="Ownership" />}
            {/* {tabIndex === 4 && <DocumentType title="Documents" />} */}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Settings;
