import React, { useEffect } from "react";
import axios from "../../api";

const DocViewer = ({ fileUrl, onClose }) => {
  useEffect(() => {
    const fetchDocx = async () => {
      try {
        const response = await axios.get(`/file/serve-doxc/${fileUrl}`, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        const downloadUrl = URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileUrl; // Use the file name or any desired name
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);

        // Call the onClose function
        onClose();
      } catch (error) {
        console.error("Error fetching DOCX file:", error);
      }
    };

    fetchDocx();
  }, [fileUrl, onClose]);

  return <p>Preparing to download DOCX file...</p>;
};

export default DocViewer;
