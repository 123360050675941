import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, message, Popconfirm } from "antd";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import "react-tabs/style/react-tabs.css";
import "./index.css";
import MUIDataTable from "mui-datatables";
import axios from "../../api";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const ListView = () => {
  const [assets, setAssets] = useState([]);
  const navigate = useNavigate();

  const fetchAssets = async () => {
    try {
      const response = await axios.get("/assests");
      const { data } = response;
      setAssets(data.list);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  const handleEdit = (id) => {
    localStorage.setItem("assetId", id);
    localStorage.setItem("mode", "edit"); // Set mode to view
    navigate("/map");
  };

  const confirmDelete = async (id) => {
    try {
      await axios.delete(`/assests/${id}`);
      message.success("Assest deleted successfully");
      fetchAssets();
    } catch (error) {
      console.error("Error details:", error);
      message.error(error.response.data.error);
    }
  };

  const handleView = (id) => {
    localStorage.setItem("assetId", id);
    localStorage.setItem("mode", "view"); // Set mode to view
    navigate("/map");
  };

  const options = {
    responsive: "standard",
    selectableRows: "none",
    selectableRowsHeader: false,
    print: false,
    download: false,
    rowsPerPageOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50], // Add 15 to the options
    rowsPerPage: 30,
  };

  const columns = [
    {
      name: "assest_name",
      label: "Site Name",
      options: {
        sort: true,
        display: "true",
        customBodyRender: (value, tableMeta) => {
          const assetId = tableMeta.rowData[4]; // Assuming asset_id is available in the same row
          return (
            <span
              style={{
                color: "#3f51b5",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleView(assetId);
              }}
            >
              {value}
            </span>
          );
        },
      },
    },

    {
      name: "address",
      label: "Address",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "site_category_name",
      label: "Category",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "material_name",
      label: "Mine Material",
      options: {
        sort: true,
        display: "true",
      },
    },

    {
      name: "assest_id",
      label: "Actions",
      options: {
        sort: false,
        display: "true",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  padding: "6px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  minWidth: "auto",
                  margin: "1px",
                }}
                onClick={() => handleEdit(value)}
              >
                <Tooltip title="Edit">
                  <EditIcon style={{ color: "#3f51b5" }} />
                </Tooltip>
              </Button>
              <Button
                style={{
                  padding: "6px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  minWidth: "auto",
                  margin: "1px",
                }}
                onClick={() => handleView(value)}
              >
                <Tooltip title="View">
                  <VisibilityIcon style={{ color: "#330066" }} />
                </Tooltip>
              </Button>
              <Popconfirm
                title="Delete the asset"
                description="Are you sure to delete this asset?"
                onConfirm={() => confirmDelete(value)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete asset">
                  <Button style={{ marginRight: "2px" }}>
                    <DeleteIcon color="error" />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          );
        },
      },
    },

    {
      name: "company_owned_by",
      label: "Company Owned By",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "owner_ship_type",
      label: "Owner Ship type",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "emergency_contact_person",
      label: "Contact Person Name",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "emergency_contact_phone",
      label: "Contact Person Number",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "latitude",
      label: "Latitude",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "longitude",
      label: "Longitude",
      options: {
        sort: true,
        display: "false",
      },
    },
  ];

  return (
    <>
      <Container fluid>
        <Box
          mt={3}
          sx={{ width: "100%", bgcolor: "background.paper", marginBottom: 10 }}
        >
          <MUIDataTable
            title="Assets List"
            data={assets}
            columns={columns}
            options={options}
          />
        </Box>
      </Container>
    </>
  );
};

export default ListView;
